import React, { useState, useEffect } from "react";
import "./App.css";
import { Select, TextField, Button, Heading, Alert } from "@navikt/ds-react";
import "@navikt/ds-css";
import styled from "styled-components";
import { HelpText } from "@navikt/ds-react";
import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import { PutCommand, DynamoDBDocumentClient } from "@aws-sdk/lib-dynamodb";

type Response = {
  temaer: Tema[];
};
type Tema = {
  tema: string;
  kontekst: Kontekst[];
};
type Kontekst = {
  beskrivelse: string;
  uttrykker: Uttrykk[];
};
type Uttrykk = {
  ce: string;
  en: string;
  no: string;
  kjoenn: string;
  grammatiskAntall: string;
};
const temaer = [
  {
    tema: "Bryllup",
    beskrivelse: "Uttrykk knyttet til bryllup og ekteskapsseremonier.",
    render: "Bryllup 👰🏻"
  },
  {
    tema: "Familiefeiringer",
    beskrivelse: "Uttrykk og setninger knyttet til familiære situasjoner og arrangementer, som bursdager, jubileer og andre familiefeiringer.",
    render: "Familiefeiringer 🎉"
  },
  {
    tema: "Gratulasjoner og Lykkeønskninger",
    beskrivelse: "Uttrykk brukt for å gratulere folk i ulike anledninger, som bryllup, fødselsdager, og nyfødte.",
    render: "Gratulasjoner og Lykkeønskninger 🌟"
  },
  {
    tema: "Hilsener",
    beskrivelse: "Vanlige hilsener og høflige uttrykk i daglig samtale.",
    render: "Hilsener 👋"
  },
  {
    tema: "Gjestfrihet og Velkomst",
    beskrivelse: "Vanlige fraser og uttrykk for å ønske gjester velkommen og vise gjestfrihet.",
    render: "Gjestfrihet og Velkomst 🏡"
  },
  {
    tema: "Ramadan",
    beskrivelse: "Uttrykk og setninger som brukes i forbindelse med den hellige måneden Ramadan i islamsk kultur. Dette kan inkludere gratulasjoner, bønner og uttrykk som brukes under faste og feiring av Ramadan.",
    render: "Ramadan 🌙"
  },
  {
    tema: "Formelle Anledninger",
    beskrivelse: "Uttrykk for bruk i formelle sammenhenger som forretningssamlinger og offisielle arrangementer.",
    render: "Formelle Anledninger 🤵💃"
  },
  {
    tema: "Helse og Velvære",
    beskrivelse: "Uttrykk relatert til helse, legebesøk, og velværeaktiviteter.",
    render: "Helse og Velvære 💊"
  },
  {
    tema: "Begravelse",
    beskrivelse: "Uttrykk og setninger som brukes i sammenheng med begravelsesseremonier og sorg.",
    render: "Begravelse ⚰️"
  },
  {
    tema: "Hverdagslige Gjøremål",
    beskrivelse: "Vanlige uttrykk for dagligdagse aktiviteter som handle, lage avtaler og organisere ting.",
    render: "Hverdagslige Gjøremål 🗒️"
  },
  {
    tema: "Reiser og Turisme",
    beskrivelse: "Uttrykk som kan være nyttige for reisende, som booking av hotell, spørre om veibeskrivelser og bestille transport.",
    render: "Reiser og Turisme 🌍"
  },
  {
    tema: "Sport og Fritid",
    beskrivelse: "Vanlige fraser knyttet til sportsbegivenheter, treningsaktiviteter og fritidsaktiviteter.",
    render: "Sport og Fritid ⚽🏊"
  },
  {
    tema: "Tid og Alder",
    beskrivelse: "Uttrykk og fraser relatert til tid og alder. Det gir språklige verktøy for å diskutere klokkeslett, datoer og aldersrelaterte emner.",
    render: "Tid og Alder 🕒🎂"
  },
  {
    tema: "Arbeid og Yrker",
    beskrivelse: "Uttrykk og fraser knyttet til arbeid og yrker. Det gir språklige verktøy for å diskutere ulike jobber og arbeidsrelaterte emner.",
    render: "Arbeid og Yrker 👩‍💼👨‍🔧"
  },
  {
    tema: "Måltider og Matlaging",
    beskrivelse: "Uttrykk knyttet til matrelaterte situasjoner, som måltider, matlaging, og spisevaner.",
    render: "Måltider og Matlaging 🍔🍳"
  },
  {
    tema: "Naturen",
    beskrivelse: "Uttrykk og fraser knyttet til naturen og naturrelaterte situasjoner. Det gir språklige verktøy for å beskrive landskap, værforhold og naturlige fenomener.",
    render: "Naturen 🌳🌦️"
  },
  {
    tema: "Kunst og Kultur",
    beskrivelse: "Uttrykk for å beskrive kunstverk, forestillinger, og kulturelle hendelser.",
    render: "Kunst og Kultur 🎨🎭"
  }
];

const kjoenns = [
  {
    value: "hankjønn",
    render: "Hankjønn 👨"
  },
  {
    value: "hunkjønn",
    render: "Hunkjønn 👩"
  },
  {
    value: "universal",
    render: "Universal"
  },
]

const grammatiskAntaller = [
  {
    value: "entall",
    render: "Entall 🏠"
  },
  {
    value: "flertall",
    render: "Flertall 🏘️"
  },
]


function findTemaByTemaName(temaName: string, temaer : {
  tema: string;
  beskrivelse: string;
}[]) {
  return temaer.find((tema) => tema.tema === temaName);
}



const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  height: 100vh;
  gap: 30px;
`;

const Container = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
`;

const SmallColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;
const StyledAlert = styled(Alert)`
  width: fit-content;
`;

const SpacedAlert = styled(StyledAlert)`
  margin-top: 12px;
`;

const StyledTextField = styled(TextField)``;

const StyledButton = styled(Button)`
  width: fit-content;
  height: fit-content;
`;

const TemaContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const StyledHelpText = styled(HelpText)``;

const client = new DynamoDBClient({});
const docClient = DynamoDBDocumentClient.from(client);

const putItem = async (requestBody: {
  tema: string;
  kontekst: string;
  ce: string;
  en: string;
  no: string;
  kjoenn: string;
  grammatiskAntall: string;
}) => {
  const url = 'https://gwjr7oc2vk.execute-api.eu-north-1.amazonaws.com/chechenphrases';

  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestBody),
  };

  try {
    const response = await fetch(url, requestOptions);

    if (response.ok) {
      console.log('Request was successful');
      // You can perform further actions here if needed
    } else {
      console.error('Request failed');
    }
  } catch (error) {
    console.error('An error occurred:', error);
  }
};


const App = () => {
  const [result, setResult] = useState<Tema[]>([]);
  const [tema, setTema] = useState<string>("");
  const [temaHelpTekst, setTemaHelpTekst] = useState<string>("");
  const [temaEmptyAlert, setTemaEmptyAlert] = useState(false);
  const [kontekst, setKontekst] = useState<string>("");
  const [kontekstEmptyAlert, setKontekstEmptyAlert] = useState(false);
  const [norsk, setNorsk] = useState<string>("");
  const [norskEmptyAlert, setNorskEmptyAlert] = useState(false);

  const [tsjetsjensk, setTsjetsjensk] = useState<string>("");
  const [tsjetsjenskAlert, setTsjetsjenskAlert] = useState(false);
  const [tsjetsjenskEmptyAlert, setTsjetsjenskEmptyAlert] = useState(false);

  const [kjoenn, setKjoenn] = useState<string>("universal");
  const [kjoennEmptyAlert, setKjoennEmptyAlert] = useState(false);

  const [grammatiskAntall, setGrammatiskAntall] = useState<string>("entall");
  const [grammatiskAntallEmptyAlert, setGrammatiskAntallEmptyAlert] = useState(false);



  const [uttrykkLagret, setUttrykkLagret] = useState(false);

  useEffect(() => {
    if (tema !== "") {
      setTemaEmptyAlert(false);
    }
  }, [tema]);

  useEffect(() => {
    if (kontekst !== "") {
      setKontekstEmptyAlert(false);
    }
  }, [kontekst]);

  useEffect(() => {
    if (kjoenn !== "") {
      setKjoennEmptyAlert(false);
    }
  }, [kjoenn]);

  useEffect(() => {
    if (grammatiskAntall !== "") {
      setGrammatiskAntallEmptyAlert(false);
    }
  }, [grammatiskAntall]);

  useEffect(() => {
    if (norsk !== "") {
      setNorskEmptyAlert(false);
    }
  }, [norsk]);

  useEffect(() => {
    if (norsk !== "") {
      setNorskEmptyAlert(false);
    }
  }, [norsk]);

  useEffect(() => {
    if (tsjetsjensk !== "") {
      setTsjetsjenskEmptyAlert(false);
    }
  }, [tsjetsjensk]);

  const handleKontekstChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKontekst(e.target.value);
  };

  const handleNorskChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNorsk(e.target.value);
  };

  const handleTsjetsjenskChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const russianAndChechenCharactersRegex = /^[а-яА-Яқіӏ\s.,!?йцукенгшщзхъэждлорпавыфячсмитьбюЙЦУКЕНГШЩЗХЪЭЖДЛОРПАВЫФЯЧСМИТЬБЮ1Ёё1II]*$/;

    if (russianAndChechenCharactersRegex.test(inputValue)) {
      // Update the state only if the input is valid
      setTsjetsjensk(inputValue);
      setTsjetsjenskAlert(false);
    } else {
      setTsjetsjenskAlert(true);
    }
  };

  const nullstillSkjema = () => {
    setTema("");
    setKontekst("");
    setNorsk("");
    setTsjetsjensk("");
    setGrammatiskAntall("entall")
    setKjoenn("universal")
    setTsjetsjenskAlert(false);
    setUttrykkLagret(false);
    setTemaEmptyAlert(false);
    setNorskEmptyAlert(false);
    setKontekstEmptyAlert(false);
    setTsjetsjenskEmptyAlert(false);
    setGrammatiskAntallEmptyAlert(false);
    setKjoennEmptyAlert(false);
  };

  const handleLagreUttrykk = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    let alert = false
    if (tema == "") {
      alert = true
      setTemaEmptyAlert(true);
    }
    if (kontekst == "") {
      alert = false
      setKontekstEmptyAlert(false);
    }
    if (tsjetsjensk == "") {
      alert = true
      setTsjetsjenskEmptyAlert(true);
    }
    if (norsk == "") {
      alert = true
      setNorskEmptyAlert(true);
    }
    if (kjoenn == "") {
      alert = true
      setKjoennEmptyAlert(true);
    }
    if (grammatiskAntall == "") {
      alert = true
      setGrammatiskAntallEmptyAlert(true);
    }
    if(alert != true) {
      const requestBody = {
        tema: tema,
        kontekst: kontekst,
        ce: tsjetsjensk,
        en: 'REPLACE ME',
        no: norsk,
        kjoenn: kjoenn,
        grammatiskAntall: grammatiskAntall,
      };
      putItem(requestBody)
      setUttrykkLagret(true);
    }
  };

  return (
    <PageContainer>
      <Heading size="small">Fyll ut et vanlig uttrykk på Tsjetsjensk</Heading>
      <Container>
        <TemaContainer>
          <SmallColumnContainer>
          <Select
            onChange={(e) => {
              const selectedTema = e.currentTarget.value;
              if(selectedTema =="") {
                setTema(selectedTema)
                setTemaHelpTekst("")
                return
              }
              const tema = findTemaByTemaName(selectedTema, temaer);
              setTema(selectedTema);
              setTemaHelpTekst(tema!!.beskrivelse);
            }}
            value={tema}
            label="Hvilket tema blir uttrykket brukt?"
          >
            <option value="">Velg tema</option>
            {temaer.map((tema) => (
              <option key={tema.tema} value={tema.tema}>
                {tema.render}
              </option>
            ))}
          </Select>

            {temaEmptyAlert!! && (
              <SpacedAlert variant="error">Tema må velges ut</SpacedAlert>
            )}
          </SmallColumnContainer>
          {tema !== "" && (
            <StyledHelpText title={temaHelpTekst}>{temaHelpTekst}</StyledHelpText>
          )}
        </TemaContainer>
        <SmallColumnContainer>
          <TextField
            onChange={handleKontekstChange}
            placeholder="Når man hilser"
            label="Hva er konteksten for uttrykket?"
            value={kontekst}
          />
          {kontekstEmptyAlert!! && (
            <SpacedAlert variant="error">Kontekst må fylles ut</SpacedAlert>
          )}
        </SmallColumnContainer>
      </Container>
      <SmallColumnContainer>
            <Select
              onChange={(e) => {
                setKjoenn(e.currentTarget.value);
              }}
              value={kjoenn}
              label="I hvilket kjønn blir uttrykket brukt?"
            >
              <option value="">Velg kjønn</option>
              {kjoenns.map((kjoenn) => (
                <option key={kjoenn.value} value={kjoenn.value}>
                  {kjoenn.render}
                </option>
              ))}
          </Select>

            {kjoennEmptyAlert!! && (
              <SpacedAlert variant="error">Kjønn må velges</SpacedAlert>
            )}
      </SmallColumnContainer>
      <SmallColumnContainer>
          <Select
              onChange={(e) => {
                setGrammatiskAntall(e.currentTarget.value);
              }}
              value={grammatiskAntall}
              label="I hvilket grammatisk antall blir uttrykket brukt?"
            >
              <option value="">Velg grammatisk antall</option>
              {grammatiskAntaller.map((v) => (
                <option key={v.value} value={v.value}>
                  {v.render}
                </option>
              ))}
          </Select>

            {grammatiskAntallEmptyAlert!! && (
              <SpacedAlert variant="error">Grammatisk antall må velges</SpacedAlert>
            )}
      </SmallColumnContainer>
      <SmallColumnContainer>
        <StyledTextField
          onChange={handleNorskChange}
          value={norsk}
          placeholder="Hvordan går det?"
          label="Norsk"
        />
        {norskEmptyAlert!! && (
          <SpacedAlert variant="error">Norsk må fylles ut</SpacedAlert>
        )}
      </SmallColumnContainer>
      <SmallColumnContainer>
        <StyledTextField
          onChange={handleTsjetsjenskChange}
          value={tsjetsjensk}
          placeholder="Хьо муха ву?"
          label="Tsjetsjensk"
        />
        {tsjetsjenskAlert!! && (
          <SpacedAlert variant="error">
            Vennligst bruk kun russiske eller tsjetsjenske bokstaver
          </SpacedAlert>
        )}
        {tsjetsjenskEmptyAlert!! && (
          <SpacedAlert variant="error">Tsjetsjensk må fylles ut</SpacedAlert>
        )}
      </SmallColumnContainer>
      <Container>
        <StyledButton variant="primary" onClick={handleLagreUttrykk}>
          Lagre uttrykk
        </StyledButton>
        <StyledButton variant="secondary" onClick={nullstillSkjema}>
          Nullstill skjema
        </StyledButton>
      </Container>
      {uttrykkLagret!! && (
        <StyledAlert variant="success">
          Uttrykket er lagret! Skriv gjerne flere
        </StyledAlert>
      )}
    </PageContainer>
  );
};

export default App;
